import PWAInstallerPrompt from 'react-pwa-installer-prompt';

export const InstallPwa = () => {
    return (
        <PWAInstallerPrompt render={({ onClick }) => (
            <div className='App-install-prompt'>
              <span>
                  Install app for offline and quick access
              </span>
              <span>
                <button type="dashed" onClick={onClick}>
                  Install
                </button>
              </span>
            </div>
          )}
          callback={(data) => console.log(data)} 
        />
    )
}