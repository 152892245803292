import { useNavigate } from "react-router-dom";
import { Accordion } from "../components/accordion";

function Tree({ info }) {
  const navigate = useNavigate();

  const returnToHome = () => {
    navigate(`/`);
  }

  if (!info) {
    navigate('/');
    return null;
  }

  console.log('render tree', info.text, info.description);

  return (
    <div>
      <div className="App-info">
        <div className="Tree-Image">
          <img src={`/images/trees/${info.folder ?? info.pic ?? info.text}/${info.pic?.src ?? info.text}.png`} />
          <div>
            <i>{info.pic.credit}</i>
          </div>
        </div>
        <h3>{info.text}</h3>
        <p>{info.description}</p>
        {info.sections ? <Accordion sections={info.sections} name={info.folder ?? info.pic ?? info.name} /> : ''}
      </div>
      <div className='App-ctas'>
        <button onClick={returnToHome}>Find more trees</button>
      </div>
    </div>
  );
}

export default Tree;