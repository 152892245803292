import { useNavigate } from "react-router-dom";
import { Map } from '../components/map';

function MapPage({ places }) {
    const location = { lat: 51.456, lng: -2.626 };

    return (
        <div>
            <div>
                <img style={{maxWidth:'95vw'}} src={"/images/trailmap.png"} />
                {/* <Map zoomLevel={13} location={location} places={places} />
                <hr />
                {places.map((x,i) => <div>{i+1}. {x.text}</div>)} */}
            </div>
        </div>
    );
}

export default MapPage;