import React, { useState, useEffect } from 'react'
import { GoogleMap, useJsApiLoader, MarkerF, CircleF, PolylineF } from '@react-google-maps/api';
import './map.css'
import { LocationPin } from './locationPin';

const color = {
    'google-blue 100': `#4285F4`,
    'white 100': `rgb(255,255,255)`,
}
const outerCircleOptions = {
    fillColor: color['google-blue 100'],
    fillOpacity: 0.2,
    radius: 70,
    scale: 8,
    strokeColor: color['white 100'],
    strokeWeight: 0,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
}
const circleOptions = {
    fillColor: color['google-blue 100'],
    fillOpacity: 0.7,
    radius: 25,
    scale: 8,
    strokeColor: color['white 100'],
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
}
const POLYLINE_OPTIONS = {
    strokeColor: '#AF9FCE',
    strokeOpacity: 0.5,
    strokeWeight: 10,
}

export const Map = ({ location, zoomLevel, places, path, currentLocation, maxZoom, minZoom, onMapLoaded }) => {
    console.log('rerender map')

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAagfogM4UQk1Ani_7oleHowctghHmimRI"
    })
    const containerStyle = {
        width: '100%',
        height: 'calc(100vh - 390px)',
        minHeight: '400px'
    };

    const POLYLINE_PATH = path;
    const [circleSize, setCircleSize] = useState(20);

    const getCircleSize = (zoomLevel) => 5;

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        console.log('loaded')
        const bounds = new window.google.maps.LatLngBounds(location)
        map.fitBounds(bounds)

        setMap(map)

        onMapLoaded && onMapLoaded()
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    // bounding box for the trail
    const restriction = {
        latLngBounds: {
            north: 51.464, south: 51.4558, west: -2.631, east: -2.622
        },
        strictBounds: true
    };

    return isLoaded ? <div className="map">
        <p>Welcome to the Tree Trail! If you stand next to any of the trees on this route, you'll unlock its info and find out all about it.</p>
        <div className="google-map">
            <GoogleMap
                containerStyle={containerStyle}
                mapContainerStyle={containerStyle}
                center={currentLocation}
                zoom={zoomLevel ?? 15}
                onLoad={onLoad}
                onUnmount={onUnmount}
                currentLocation={currentLocation}
                onZoomChanged={() => setCircleSize(getCircleSize(map?.zoom))}
                options={{
                    clickableIcons: true,
                    disableDefaultUI: true,
                    fullscreenControl: false,
                    isFractionalZoomEnabled: false,
                    mapTypeControl: false,
                    mapTypeControlOptions: {
                        mapTypeIds: ['roadmap', 'satellite']
                    },
                    mapTypeId: 'roadmap',
                    maxZoom: maxZoom,
                    minZoom: minZoom,
                    restriction: restriction,
                    streetView: null,
                    styles: [
                        {
                            featureType: 'poi',
                            stylers: [{ 'visibility': 'off' }]
                        },
                        {
                            featureType: "road",
                            elementType: "labels",
                            stylers: [{ 'visibility': 'off' }]
                        }
                    ],
                    zoomControl: true
                }}
            >
                {<PolylineF path={POLYLINE_PATH} options={POLYLINE_OPTIONS} />}

                {currentLocation && <>
                    <CircleF options={{ ...circleOptions, radius: circleSize, center: { lat: currentLocation.lat, lng: currentLocation.lng } }} />
                    <CircleF options={{ ...outerCircleOptions, radius: circleSize*1.5, center: { lat: currentLocation.lat, lng: currentLocation.lng } }} />
                </>}
                {places && places.map((marker, i) => (
                    <MarkerF key={`item-${i}`} text={marker.text} position={{ lat: marker.lat, lng: marker.lng }} isCurrent={false} title={`${i+1}`} />
                ))}
            </GoogleMap>
        </div>
    </div> : <></>
};