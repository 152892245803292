import { useState } from "react"
import './accordion.css'

const parseInfo = (info) => {
    if (info.indexOf('[Link]') == -1) return info;

    const urlRegex = /url=\'(.*?)\'/
    const textRegex = /text=\'(.*?)\'/

    const url = info.match(urlRegex)
    const text = info.match(textRegex)

    return <a target="_blank" href={url[1]}>{text[1]}</a>;
}

const showTree = (name, src, credit) => {
    return <div>
        <img src={`/images/trees/${name}/${src}`} width={300} />
        <div><i>{credit}</i></div>
    </div>
}

export const Accordion = ({sections,name}) => {
    const [currentSection, setCurrentSection] = useState(sections[0].title)

    const Section = ({info}) => {
        return <div className="Accordion">
            <div className={`AccordionItem ${currentSection === info.title ? '' : 'Collapsed'}`} onClick={() => currentSection === info.title ? setCurrentSection(null) : setCurrentSection(info.title)}>
                <div className="AccordionTitle">
                    <div className="AccordionHighlight"></div>
                    <div className="AccordionTitleInfo">
                        {info.title}
                    </div>
                </div>
                <div className="AccordionInfo">
                    {parseInfo(info.info)}
                    {info.photos ? <div className="AccordionPhotos">{info.photos.map(x => showTree(name, x.src, x.credit))}</div> : <></>}
                </div>
            </div>
        </div>
    }

    return <div>
        {sections.map(x => <Section info={x} />)}
    </div>
}