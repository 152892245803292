function About({ }) {
    return (
        <div>
            <h1>About</h1>
            <div style={{textAlign: "center"}}>
                <img style={{maxWidth:"90vw"}} src="/images/observatory.jpeg"></img>
            </div>
            <p>The Clifton Observatory Tree Trail has been developed by Sarah’s Wildlife Projects in collaboration with and in support of the Avon Gorge and Downs Wildlife Project (AGDWP). It is intended to be an educational tool for young humans and their parents, carers, and teachers to help foster a knowledge and understanding of trees and how these fit within the world.</p>
            <p>
                <i>Enjoyed the trail? Got suggestions? <a href="https://forms.gle/Qk5QMAntmCvPiQ3JA" target="_blank">Leave us feedback</a></i>
            </p>
        </div>
    );
}

export default About;