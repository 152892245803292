// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
}

.google-map {
    width: 100%;
}

.google-map > div {
    height: 100%;
}


.pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
}

.pin-icon {
    font-size: 4rem;
}

.pin-text {
    font-size: 1.3em;
}

@media screen and (min-width: 799px) {
    .google-map {
        height: 80vh;
    }

    .map-h2 {
        font-size: 1.3rem;
        font-weight: 400;
    }

    .pin {
        width: 15vw;
    }

    .pin-icon {
        font-size: 10vw;
    }
}

.pin {
    position: relative;
    top: -9px;
    left: -9px;
    width: 18px;
    height: 18px;
    background-color: rgb(0, 0, 0);
    border: 2px solid #fff;
    border-radius: 100%;
    -webkit-user-select: none;
            user-select: none;
    transform: translate(-50%, -50%);
}

.pin.current {
    background-color: rgb(51, 110, 197);
}`, "",{"version":3,"sources":["webpack://./src/components/map.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,iBAAiB;QACjB,gBAAgB;IACpB;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,sBAAsB;IACtB,mBAAmB;IACnB,yBAAiB;YAAjB,iBAAiB;IACjB,gCAAgC;AACpC;;AAEA;IACI,mCAAmC;AACvC","sourcesContent":[".map-h2 {\r\n    text-transform: uppercase;\r\n    font-size: 1rem;\r\n    padding: 20px;\r\n    padding-left: 10px;\r\n    text-align: center;\r\n}\r\n\r\n.google-map {\r\n    width: 100%;\r\n}\r\n\r\n.google-map > div {\r\n    height: 100%;\r\n}\r\n\r\n\r\n.pin {\r\n    display: flex;\r\n    align-items: center;\r\n    width: 180px;\r\n    color: var(--main-blue);\r\n}\r\n\r\n.pin-icon {\r\n    font-size: 4rem;\r\n}\r\n\r\n.pin-text {\r\n    font-size: 1.3em;\r\n}\r\n\r\n@media screen and (min-width: 799px) {\r\n    .google-map {\r\n        height: 80vh;\r\n    }\r\n\r\n    .map-h2 {\r\n        font-size: 1.3rem;\r\n        font-weight: 400;\r\n    }\r\n\r\n    .pin {\r\n        width: 15vw;\r\n    }\r\n\r\n    .pin-icon {\r\n        font-size: 10vw;\r\n    }\r\n}\r\n\r\n.pin {\r\n    position: relative;\r\n    top: -9px;\r\n    left: -9px;\r\n    width: 18px;\r\n    height: 18px;\r\n    background-color: rgb(0, 0, 0);\r\n    border: 2px solid #fff;\r\n    border-radius: 100%;\r\n    user-select: none;\r\n    transform: translate(-50%, -50%);\r\n}\r\n\r\n.pin.current {\r\n    background-color: rgb(51, 110, 197);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
