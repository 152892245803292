import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes, Outlet, Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Tree from './pages/tree';
import Home from './pages/home';
import About from './pages/about';
import MapPage from './pages/map';
import { preloadImages } from './functions/preloadImages';
import { InstallPwa } from './components/installpwa';

function App() {
  const [places, setPlaces] = useState([])
  const [path, setPath] = useState([])
  const [currentLocation, setCurrentLocation] = useState()
  const [startLocation, setStartLocation] = useState()
  const [inRangeTree, setInRangeTree] = useState()
  const [mapLoaded, setMapLoaded] = useState(false)

  const mode = process.env.REACT_APP_MODE || 'production';

  console.log(process.env);

  if (mode == 'production') {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setCurrentLocation(pos);
        if (!startLocation)
          setStartLocation(pos);
      }, null, { enableHighAccuracy: true, maximumAge: 2000, timeout: 5000 })
    }
  }

  // for testing only!
  function handleKeyDown(event) {
    if (mode != 'development') return;

    const moveDistance = 0.0001;

    if (event.ctrlKey || event.metaKey) {
      if (event.key === "s") {
        setCurrentLocation({ lat: currentLocation.lat + moveDistance, lng: currentLocation.lng })
        event.preventDefault();
      }
      if (event.key === "a") {
        setCurrentLocation({ lat: currentLocation.lat, lng: currentLocation.lng - moveDistance })
        event.preventDefault();
      }
      if (event.key === "d") {
        setCurrentLocation({ lat: currentLocation.lat, lng: currentLocation.lng + moveDistance })
        event.preventDefault();
      }
      if (event.key === "x") {
        setCurrentLocation({ lat: currentLocation.lat - moveDistance, lng: currentLocation.lng })
        event.preventDefault();
      }
    }
  }

  useEffect(() => {
    if (mode == 'development') {
      setCurrentLocation({ lat: 51.456487, lng: -2.624978 });
      if (!startLocation) {
        setStartLocation({ lat: 51.456487, lng: -2.624978 });
      }
    }
  }, [])

  const fetchPlaces = async () => {
    fetch('/data/trees.json')
      .then((response) => response.json())
      .then((data) => setPlaces(data));
  };

  // todo - not needed at this level, could move into homepage
  const fetchPath = async () => {
    fetch('/data/path.json')
      .then((response) => response.json())
      .then((data) => setPath(data));
  };

  useEffect(() => {    
    if (places && mapLoaded) {
      console.log('places loaded - preloading all images now!')
      const imgs = places.flatMap(x => { return { sections: x.sections.filter(y => !!y.photos).flatMap(y => y.photos.map(l => `${x.folder}/${l.src}`)) } })
      const imgs2 = imgs.filter(x => !!x).flatMap(x => x.sections)

      const imgs3 = places.flatMap(x => `${x.folder}/${x.pic.src}.png`)

      preloadImages(imgs2.concat(imgs3))
    }
  }, [places, mapLoaded]);

  useEffect(() => {
    fetchPlaces();
    fetchPath();
  }, []);

  useEffect(() => {
    if (currentLocation && places && places.length) {
      const maxAllowedDistance = 0.0001;

      const minLat = currentLocation.lat - maxAllowedDistance;
      const maxLat = currentLocation.lat + maxAllowedDistance;
      const minLng = currentLocation.lng - maxAllowedDistance;
      const maxLng = currentLocation.lng + maxAllowedDistance;

      const t = places.map(x => {
        const isGreaterThanMinLat = x.lat >= minLat;
        const isLesserThanMaxLat = x.lat <= maxLat;

        const isGreaterThanMinLng = x.lng >= minLng;
        const isLesserThanMaxLng = x.lng <= maxLng;

        const isInRange = isGreaterThanMinLat && isLesserThanMaxLat && isGreaterThanMinLng && isLesserThanMaxLng;

        console.log('is in range', isInRange, x.text, currentLocation);

        return { ...x, inRange: isInRange };
      })

      const firstInRange = t.find(x => x.inRange == true);
      console.log('setting first tree', firstInRange);

      if (!inRangeTree && firstInRange && !!navigator.vibrate) {
        navigator.vibrate([200]);
      }

      setInRangeTree(firstInRange);
    }
    else {
      setInRangeTree(null);
    }

  }, [currentLocation, places])

  const onMapLoaded = () => {
    setMapLoaded(true)
  }

  return (
    <div className="App" onKeyDown={handleKeyDown}>
      <div>
        <InstallPwa />
      </div>
      <div className="App-content">
        <div className="App-content-header">
          <span>
            <img src='/images/SWP.jpeg' style={{ width: '120px', marginRight: '10px' }} />
          </span>
          <h1>Clifton Tree Trail</h1>
        </div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home inRangeTree={inRangeTree} hasInRangeTree={!!inRangeTree} zoomLevel={18} currentLocation={currentLocation} location={startLocation} places={places} path={path} onMapLoaded={onMapLoaded} />}>
              <Route path="/tree/:treeId" element={<Tree info={inRangeTree} />} />
              <Route path="/about" element={<About />} />
              <Route path="/map" element={<MapPage places={places} />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>

    </div>
  );
}

export default App;
