import { useEffect, useState } from "react";
import { useNavigate, useLocation, Outlet, Link } from "react-router-dom";
import { Map } from '../components/map';

const Layout = () => {
    const location = useLocation().pathname

    return <>
        <div className="App-header">
            <Link className={location == '/' || location.startsWith('/tree') ? 'Selected' : ''} to="/">
                <img src="/icons/home.png" height="30px"></img>
                <div>Home</div>
            </Link>
            <Link className={location == '/about' ? 'Selected' : ''} to="/about">
                <img src="/icons/about.png" height="30px"></img>
                <div>About</div>
            </Link>
            <Link className={location == '/map' ? 'Selected' : ''} to="/map">
                <img src="/icons/explore.png" height="30px"></img>
                <div>Map</div>
            </Link>
        </div>
    </>
}

function Home({ hasInRangeTree, zoomLevel, places, path, location, currentLocation, inRangeTree, onMapLoaded }) {
    const navigate = useNavigate();
    const currentPath = useLocation();

    const [mapVisible, setMapVisible] = useState(true);

    useEffect(() => {
        setMapVisible(currentPath.pathname == "/")
    }, [currentPath.pathname]);

    const viewTree = () => {
        navigate(`/tree/${inRangeTree.pic.src}`)
    }

    const className = mapVisible ? "" : "Hidden";

    return (
        <>
            <div>
                <div className={className}>
                    <div>
                        {<Map
                            zoomLevel={zoomLevel}
                            currentLocation={currentLocation}
                            location={location}
                            places={places}
                            path={path}
                            maxZoom={20}
                            minZoom={15}
                            onMapLoaded={onMapLoaded}
                            />}
                    </div>

                    <div className='App-ctas'>
                        <button onClick={viewTree} disabled={!hasInRangeTree}>{hasInRangeTree ? 'Tree unlocked! View!' : 'No nearby trees'}</button>
                    </div>
                </div>
                <div>
                    <Outlet />
                </div>
            </div>
            <Layout />
        </>
    );
}

export default Home;